export const appPaths = {
    adminAccess: 'adminaccess',
    forgotPassword: 'forgotpassword',
    home: 'home',
    login: 'login',
    myProfile: 'my-profile',
    providerDashboard: 'dashboard',
    resetPassword: 'resetpassword',
    settings: 'settings',
    signOut: 'sign-out',
};
