<h3 mtSetTitle>Completed Activity Report</h3>

<div class="miles-card padded">
    <h4>COMPLETED ACTIVITY REPORT</h4>

    <!-- Filters -->
    <div class="row">
        <div class="col-md-2" *ngIf="!districtId">
            <label>School Districts</label>
            <mt-virtual-type-ahead
                [getItems]="getSchoolDistricts"
                [placeholder]="'Begin typing...'"
                (selectionChanged)="handleDistrictSelection($event)"
            > </mt-virtual-type-ahead>
        </div>
        <div class="col-md-4" *ngIf="serviceCodes && serviceCodes.length">
            <label>Service Area</label><br />
            <mt-search-filter-select
            [items]="serviceCodes"
            entity="ServiceCodes"
            [maxToShowInSelectedText]="1"
            ></mt-search-filter-select>
        </div>
    </div>
    <br />

    <!-- Buttons -->
    <div class="row">
        <div class="col-md-4">
            <button
                type="button"
                class="btn btn-info"
                [disabled]="!serviceCodes || !serviceCodes.length"
                (click)="getCompletedActivityReport()">Get Completed Activity Report
            </button>
        </div>
    </div>
</div>
