import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
/* This is a work around - based on the following issue (https://gitlab.4miles.com/Engineering/Breckenridge/issues/91) resolution we should update the references to this class with the new implementation */

@Injectable({
    providedIn: 'root',
})
export class DateTimeConverterService {
    constructor(private datePipe: DatePipe) {}
    convertToUTC(dateTime: Date): Date {
        const date = new Date(dateTime);
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
    }

    convertUtcToLocal(dateTime: Date): Date {
        const date = new Date(dateTime);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() - date.getTimezoneOffset());
    }

    GetDateTimeForTimeZone(date: Date, TimeZone: string): moment.Moment {
        return moment.tz(date, TimeZone);
    }

    convertDateToBrowserTimeZoneString(dateTime: Date): string | moment.Moment {
        return this.datePipe.transform(dateTime, 'MMMM d, yyyy, hh:mm a');
    }

    convertTimeToBrowserTimeZoneString(time: string, encounterDate: Date): string {
        const date = new Date(encounterDate);
        const utc_date = this.appendTimeSpanToDate(new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
        date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())), time);
        return this.datePipe.transform(this.convertUtcToLocal(utc_date), 'HH:mm:ss');
    }

    convertToDate(dateTime: Date): string {
        return this.datePipe.transform(dateTime, 'MMMM d, yyyy');
    }

    convertToDateSlashes(dateTime: Date): string {
        return this.datePipe.transform(dateTime, 'MM/dd/yyyy', 'UTC');
    }

    convertToTimeString(time: string): string {
        const today = new Date();
        const date = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            Number.parseInt(time.slice(0, 2), null),
            Number.parseInt(time.slice(3, 5), null));
        return this.datePipe.transform(date, 'h:mm a');
    }

    assumeLocalTimeForUTCDateObject(value: Date): Date {
        if (!value) {
            return null;
        }
        const dateAsString = value.toString();
        return new Date(dateAsString.substring(0, dateAsString.length - 1));
    }

    appendTimeSpanToDate(date: Date, time: string): Date {
        const response = new Date(date);
        const timeSegments = time.split(':');
        const hours = timeSegments[0];
        const minutes = timeSegments[1];
        response.setHours(Number(hours));
        response.setMinutes(Number(minutes));
        response.setSeconds(0);

        return response;
    }

    getDateDurationInMins(start: Date, end: Date): number {
        const minutesDiff = moment(end).diff(moment(start), 'minutes');

        return minutesDiff;
    }

    getTimeDurationInMins(start: string, end: string): number {
        const startMoment = moment(this.appendTimeSpanToDate(new Date(), start));
        const endMoment = moment(this.appendTimeSpanToDate(new Date(), end));
        const minutesDiff = endMoment.diff(startMoment, 'minutes');

        return minutesDiff;
    }
}
