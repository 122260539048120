export enum ClaimTypes {

    AppSettings = 1,
    HPCUserAccess = 2,
    DistrictUserAccess = 3,
    ProviderTitles = 4,
    ProviderGoals = 5,
    ProviderMethods = 6,
    CPTCodes = 7,
    DiagnosisCodes = 8,
    ApprovedLocation = 9,
    ContractedAgency = 10,
    ReturnReasons = 11,
    Documents = 12,
    Links = 13,
    Users = 14,
    BillingSchedules = 15,
    ReviewSchedules = 16,
    ReviewFiles = 17,
    RejectedEncounters = 18,
    MessageMaintenance = 19,
    PublicMessage = 20,
    ClientMessage = 21,
    TargetMessage = 22,
    ProviderAttestations = 23,
    ProviderAcknowledgements = 24,
    ESignaAuthorization = 25,
    MedicalReferralAuthorization = 26,
    SchoolDistrictMaintenance = 27,
    SchoolDistricts = 28,
    SchoolDistrictAdministrationUsers = 29,
    SchoolDistrictManagementInformation = 30,
    ESCMaintenance = 31,
    ESCs = 32,
    ESCAdministrationUsers = 33,
    ESCManagementInformation = 34,
    ProviderMaintenance = 35,
    StudentMaintenance = 36,
    Encounters = 37,
    MedMatch = 38,
    Reports = 39,
    ReviewParentConsent = 40,
    DistrictActivitySummaryByServiceArea = 41,
    ServiceAreaSummaryByProvider = 42,
    ProviderActivityDetailReport = 43,
    EncounterReportingByStudent = 44,
    EncounterReportingByTherapist = 45,
    Rosters = 46,
    RosterIssues = 47,
    RosterUpload = 48,
    Students = 49,
    CreateStudent = 50,
    MergeStudent = 51,
    ManagedListItems = 52,
    ReviewStudent = 53,
    CreateTherapyEncounter = 54,
    EncountersReadyForYou = 55,
    CreateEvaluation = 56,
    PendingEvaluation = 57,
    Revise = 58,
    CreateNonMSPService = 59,
    ReviewEncounters = 60,
    ScheduleTherapyCalendar = 61,
    MyCaseload = 62,
    MissingReferrals = 63,
    CaseNotesDataBank = 64,
    ProgressReports = 65,
    Vouchers = 66,
    CompletedReferrals = 67,
    BillingReversals = 68,
    IneligibleClaims = 69,
    FiscalRevenue = 70,
    FiscalSummary = 71,

    // AppSettings = 1,
    // Users = 2,
    // UserRoles = 3,
    // Audit = 4,
    // SchoolDistricts = 5,
    // Providers = 6,
    // ManagedListItems = 7,
    // ESCs = 8,
    // Schools = 9,
    // Acknowledgments = 10,
    // Students = 11,
    // ProviderAcknowledgments = 12,
    // ProviderMessages = 13,
    // Encounters = 14,
    // AuditClaims = 15,
    // BillingSchedules = 16,
    // RosterValidation = 17,
}
