<div class="row">
    <div class="col-md-12">
        <div class="panel panel-info widget miles-card">
            <div class="panel-heading clearfix">
                Documents and Links
                <div class="resize-block-element pull-right"></div>
            </div>

            <div class="panel-body action-bottom">
                <ul class="list-group scroll-list">
                    <li class="list-group-item" *ngIf="!documentLinksView || !documentLinksView.length">
                        <h4 style="border: none;">No documents or links.</h4>
                    </li>
                    <div *ngIf="documentLinksView && documentLinksView.length">
                        <li class="list-group-item" *ngFor="let documentLink of documentLinksView" (click)="goToSelectedPage(documentLink)">
                            <h4 style="border: none;">{{ documentLink.Name }} {{ getDueDate(documentLink) }} 
                                <i *ngIf="isLink(documentLink)" class="fa fa-globe"></i>
                                <i *ngIf="!isLink(documentLink) && isPdf(documentLink)" class="fa fa-file-pdf-o"></i>
                                <i *ngIf="!isLink(documentLink) && !isPdf(documentLink)" class="fa fa-file-text"></i>
                            </h4>
                        </li>
                    </div>
                </ul>
                <br />
                <div class="action" *ngIf="expandable && !expanded">
                    <a (click)="expandView()">View Full List</a>
                </div>
                <div class="action" *ngIf="expandable && expanded">
                    <a (click)="collapseView()">Collapse View</a>
                </div>
            </div>
        </div>
    </div>
</div>
