import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { EncounterResponseDtoService } from '@common/services/encounter-response-dto.service';
import { IEncounterResponseDto } from '@model/interfaces/custom/encounter-response.dto';
import { EntityListExportConfig, EntityListExportService } from '@mt-ng2/entity-list-module';
import { Observable, of } from 'rxjs';
import { AupAuditExportEntityListConfig } from './aup-audit-export.entity-list-config';

@Component({
    selector: 'app-aup-audit',
    templateUrl: './aup-audit.component.html',
})
export class AupAuditComponent implements OnInit {
    @Input() allEncounters: IEncounterResponseDto[];
    @Input() encounters: IEncounterResponseDto[];
    @Output() encountersChange = new EventEmitter<IEncounterResponseDto[]>();
    entityListConfig = new AupAuditExportEntityListConfig(this.encounterResponseDtoService);
    protected entityListExportService: EntityListExportService;

    constructor(
        private encounterResponseDtoService: EncounterResponseDtoService,
        injector: Injector) {
        this.entityListExportService = injector.get(EntityListExportService);
    }

    ngOnInit(): void {
        this.entityListConfig.export = new EntityListExportConfig({ exportName: 'AUP Audit List', getDataForExport: this.getEncountersForExport.bind(this) });
    }

    removeEncounterFromList(encounter: IEncounterResponseDto): void {
        this.encounters = this.encounters.filter((e) => e.EncounterNumber !== encounter.EncounterNumber);
        this.encountersChange.emit(this.encounters);
    }

    export(): void {
        if (this.entityListConfig.export.getDataForExport) {
            this.entityListConfig.export.getDataForExport().subscribe((data) => {
                this.entityListExportService.export(data, this.entityListConfig);
            });
        }
    }

    clearAll(): void {
        this.encounters = [];
        this.encountersChange.emit(this.encounters);
    }

    getEncountersForExport(): Observable<IEncounterResponseDto[]> {
        return of(this.encounters);
    }

    getAll(): void {
        this.encounters = this.allEncounters;
        this.encountersChange.emit(this.encounters);
    }
}
