export enum EncounterStatuses {
    New = 1,
    E_Signed,
    Approve_Encounter,
    Hold_For_Research,
    Do_Not_Bill_Provider_Ineligible,
    Returned_By_Admin,
    Returned_By_Supervisor,
    Abandoned,
    READY_FOR_SUPERVISOR_ESIGN,
    NON_MSP_SERVICE,
    NON_IEP,
    DEVIATED,
    OPEN_ENCOUNTER_READY_FOR_YOU,
    PENDING_TREATMENT_THERAPY,
    PENDING_EVALUATION_ASSESSMENT,
    NON_CONSENT,
    ENCOUNTER_ENTERED_PRIOR_TO_CONSENT,
    NO_REFFERAL,
    ENCOUNTER_ENTERED_PRIOR_TO_REFERRAL,
    ADDRESS_ISSUE,
    Invoiced,
    Invoiced_and_Paid,
    Invoiced_and_Denied,
    Invoice_0_service_units,
    Not_a_medicaid_student,
    Claims_older_than_365_Days,
    Pending_Consent,
    Corrected_Return,
    Abandoned_Claim,
    READY_FOR_BILLING,
    SCHEDULED_FOR_REVERSAL,
    DENIED_AND_REBILLED,
    MISSING_MEDICAID_NUMBER,
    PENDING_REVERSAL,
    DO_NOT_BILL,
    PAID_AND_REVERSED
}
