<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Missing Student Addresses</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="getFilterSearchbar($event)" (ready)="searchControlReady($event)"></mt-search-bar>
    <br />
</div>
<div class="row">
    <div class="col-md-3" *ngIf="isAdmin">
        <label
            >School Districts
            <span style="color: red;" *ngIf="schoolDistrictIdFilter === 0">
                - Select A School District
            </span>
        </label>
        <mt-virtual-type-ahead
            [getItems]="getSchoolDistricts"
            [placeholder]="'Begin typing...'"
            (selectionChanged)="getFilterSchoolDistrictSearchbar($event)">
        </mt-virtual-type-ahead>
    </div>
    <div class="col-md-2">
        <button type="button" Class="btn btn-flat btn-success" (click)="applyClicked($event)">
            Apply
        </button>
    </div>
</div>
<br />

<mt-entity-list
    [entities]="students"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getStudents()"
    (itemSelectedEvent)="studentSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>