<div class="row">
    <div class="col-md-7">
        <h2 mtSetTitle>Encounters AUP Audit</h2>
    </div>
</div>
<!-- Search Bar -->
<div>
    <mt-search-bar (searched)="getFilterSearchbar($event)" (ready)="searchControlReady($event)"></mt-search-bar>
</div>
<!-- Encounter Number Search Bar -->
<div>
    <label>Search Encounter Numbers</label>
    <mt-search-bar (searched)="getFilterEncounterNumbersSearchbar($event)"
        (ready)="encounterSearchControlReady($event)"></mt-search-bar>
    <br />
</div>

<!-- Filters -->
<div class="row">
    <div class="col-md-2">
        <label>School Districts</label>
        <mt-virtual-type-ahead [getItems]="getSchoolDistricts" [placeholder]="'Begin typing...'"
            (selectionChanged)="getFilterSchoolDistrictSearchbar($event)"> </mt-virtual-type-ahead>
    </div>
    <div class="col-md-2">
        <label>Providers</label>
        <mt-virtual-type-ahead [getItems]="getProviders" [placeholder]="'Begin typing...'"
            (selectionChanged)="getFilterProviderSearchbar($event)"> </mt-virtual-type-ahead>
    </div>
    <div class="col-md-3">
        <label>Encounter Date</label><br />
        <mt-search-filter-daterange [startDate]="startDate" [endDate]="endDate" entity="Date"
            (selectionChanged)="getFilterDateRange($event)">
        </mt-search-filter-daterange>
    </div>
    <div class="col-md-2">
        <label>CPT Code (Procedure Code)</label><br />
        <mt-search-filter-select [items]="cptCodes" entity="CPT Codes"
            [maxToShowInSelectedText]="1"></mt-search-filter-select>
    </div>
    <div class="col-md-2">
        <label>Service Areas</label><br />
        <mt-search-filter-select [items]="serviceCodes" entity="Service Areas"
            [maxToShowInSelectedText]="1"></mt-search-filter-select>
    </div>
    <div class="col-md-2">
        <button type="button" Class="btn btn-flat btn-success" (click)="applyClicked($event)">
            Apply
        </button>
    </div>
</div>
<br />

<!-- Entity List -->
<div class="col-md-8">
    <mt-entity-list [entityListConfig]="entityListConfig" [entities]="encounters" [total]="total"
        [(currentPage)]="currentPage" (pageChanged)="getEncounters()">
        <ng-template mtEntityListHeader>
            <th [style.width]="'15%'">
                Student Info
            </th>
            <th [style.width]="'20%'">
                Encounter Info
            </th>
            <th [style.width]="'30%'">
                Other
            </th>
            <th [style.width]="'35%'">
                Notes
            </th>
        </ng-template>
        <ng-template mtEntityListItem let-columns="columns" let-entity>
            <tr (click)="encounterSelected(entity)">
                <td [style.width]="'15%'">
                    <label>Name: </label> {{ entity.StudentName }} <br />
                    <label>District: </label> {{ entity.SchoolDistrict }} <br />
                    <label>Provider: </label> {{ entity.ProviderName }} <br />
                    <label>Medicaid #: </label> {{ entity.MedicaidNo }} <br />
                </td>
                <td [style.width]="'20%'">
                    <label>Encounter #: </label> {{ entity.EncounterNumber }} <br />
                    <label>Claim Id: </label> {{ entity.ClaimId }} <br />
                    <label>Status: </label> <a (click)="encounterStatusLogSelected(entity)">{{ entity.CurrentStatus
                        }}</a> <br />
                    <label>Date: </label> {{ entity.EncounterDate | date: 'mediumDate' }} <br />
                    <label>Start: </label> {{ entity.StartDateTime | date: 'shortTime' }} <br />
                    <label>End: </label> {{ entity.EndDateTime | date: 'shortTime' }} <br />
                    <label>Total Minutes: </label> {{ getSessionMinutes(entity) }} minutes<br />
                    <label>Grouping: </label> {{ entity.NumStudentsInEncounter }} <br />
                </td>
                <td [style.width]="'30%'">
                    <label>Service Areas: </label> {{ entity.ServiceArea }} <br />
                    <label>Reason For Service: </label> {{ entity.ReasonforService }} <br />
                    <label>CPT Code (Procedure Code): </label>
                    <p>{{ convertCptCodesToCommaSeparatedList(entity.CptCodes) }}</p>
                    <label>Methods: </label> {{ convertMethodsToCommaSeparatedList(entity.Methods) }} <br />
                    <label>Plan Goals: </label> {{ convertItemsToCommaSeparatedList(entity.Goals) }} <br />
                </td>
                <td [style.width]="'35%'">
                    <label>Treatment Notes: </label> {{ entity.TreatmentNotes }} <br />
                    <label>Supervisor Comments: </label>
                    <i *ngIf="entity.SupervisorComments && entity.SupervisorComments.length > 50" data-toggle="tooltip"
                        [title]="entity.SupervisorComments">
                        {{ getAbbrigedComments(entity.SupervisorComments) }}
                    </i>
                    <span *ngIf="entity.SupervisorComments && entity.SupervisorComments.length <= 50">
                        {{ entity.SupervisorComments }}
                    </span>
                    <br />
                    <div *ngIf="entity.CurrentStatusId === encounterStatuses.Abandoned">
                        <label>Notes for Abandonment: </label> {{ entity.AbandonmentNotes }} <br />
                    </div>
                </td>
            </tr>
        </ng-template>
    </mt-entity-list>
</div>

<!--AUP Audit List-->
<div class="col-md-4">
    <app-aup-audit [allEncounters]="encounters" [(encounters)]="selectedEncounters"></app-aup-audit>
</div>


<mt-modal-wrapper *ngIf="showStatusesModal" [options]="modalOptions" (cancelClick)="toggleStatusModal()">
    <h4>Encounter Status Log</h4>
    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Encounter Status</th>
                    <th>Date</th>
                    <th>Changed By</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let status of encounterStudentStatusesForModal">
                    <td class="text-left">
                        {{ status.StatusName }}
                    </td>
                    <td class="text-left">
                        {{ status.DateCreated | date: 'mediumDate' }}
                    </td>
                    <td class="text-left">
                        {{ status.CreatedBy }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <br />
    <button type="button" class="btn btn-default" (click)="toggleStatusModal()">Close</button>
</mt-modal-wrapper>
