import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IServiceCode } from '@model/interfaces/service-code';
import { StaticMetaItemService } from '@mt-ng2/base-service';

@Injectable({
    providedIn: 'root',
})
export class ServiceCodeService extends StaticMetaItemService<IServiceCode> {
    constructor(public http: HttpClient) {
        super('ServiceCodeService', 'Service Code', 'ServiceCodeIds', '/options/serviceCodes', http);
    }

    getBillableServiceCodes(): IServiceCode[] {
        return this.items.filter((servicecode) => servicecode.IsBillable);
    }
}
